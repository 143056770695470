<template>
  <v-col sm="12">
    <loader
      color="#004AAD"
      :loading="loading"
      v-if="loading"
    />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between tw-flex-wrap">
         <analytic-count-card color="#1492ff" :title="getCurrentDay+ ' Online Booking'" :count="todayBookings.onlineBooking+''" class="">

         </analytic-count-card>
        <analytic-count-card :title="getCurrentDay+ ' Offline Booking'" class="" :count="todayBookings.offlineBooking+''">

        </analytic-count-card>
        <analytic-count-card color="#BD0200" :title="'Online bookings for '+getCurrentMonth" class="" :count="monthBookings.onlineBooking+''">

        </analytic-count-card>
        <analytic-count-card color="#B51ECD" :title="'Offline bookings for '+getCurrentMonth" class="" :count="monthBookings.offlineBooking+''">

        </analytic-count-card>

        <analytic-count-card color="#1892bf" :title="'Total bookings for '+getCurrentMonth" :count="monthBookings.totalBooking+''">

        </analytic-count-card>

      </div>
      <div
        class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-between lg:tw-py-5
          tw-mt-20
          lg:tw-mt-5
          table-container-div
          tw-p-5
          lg:tw-pl-8
        "
        style="background: white"
      >
        <div class="table-container lg:tw-pr-8">
          <div
            class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">

            <p
              class="table-header"
              style="color: #004AAD"
            >
              All Bookings
            </p>
            <div
              class="
                tw-flex tw-flex-row tw-justify-end
                lg:tw-justify-center
                tw-items-center
              "
            >
              <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Enter Booking Ref Id"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon color="#004AAD" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-menu bottom offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <td class="d-flex justify-center">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon color="#004AAD">
                              mdi-filter-variant
                            </v-icon>
                          </v-btn>
                        </td>
                      </template>
                      <v-list>
                        <v-list-item class="">
                          <v-list-item-title
                              style="color: black"
                              class="tw-flex-row tw-flex tw-cursor-pointer"
                              @click="getFilterBookings('All')">
                            All
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="">
                          <v-list-item-title
                              style="color: black"
                              class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center"
                              @click="getFilterBookings('Online')"
                          >
                            Online
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="">
                          <v-list-item-title
                              style="color: black"
                              class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center"
                              @click="getFilterBookings('Offline')"
                          >
                            Offline
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                  </template>
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="true"
            :items="filterBookings"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            checkbox-color="#004AAD"
            @click:row="handleViewABooking"
            :items-per-page.sync="paginator.itemsPerPage"
            :page.sync="paginator.page"
          >
            <template #footer v-if="bookings.length > 10">
              <div class="tw-flex tw-w-full tw-justify-end tw-items-center td-style">
                Items per Page
                <div  class="tw-mx-3 tw-w-1/12">
                  <v-select hide-details solo flat :items="itemsPerPage" v-model="paginator.itemsPerPage"></v-select>
                </div>
                Page {{paginator.page}} of {{numberOfPages}}
                <v-btn @click="handlePrevious" text fab small :disabled="paginator.page===1">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn @click="handleNext" text fab small :disabled="paginator.page === numberOfPages" :loading="pageDataLoading">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

              </div>
            </template>
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.firstName>
              <span class="status">First Name</span>
            </template>
            <template v-slot:[`item.firstName`]="{ item }">
              <span class="td-style text-capitalize">{{
                getFirstName(item)
              }}</span>
            </template>
            <template #header.lastName>
              <span class="status">Last Name</span>
            </template>
            <template v-slot:[`item.lastName`]="{ item }">
              <span class="td-style text-capitalize">{{
                getLastName(item)
              }}</span>
            </template>
            <template #header.bookingRefId>
              <span class="status">Booking Ref ID</span>
            </template>
            <template v-slot:[`item.bookingRefId`]="{ item }">
              <span class="td-style text-uppercase">{{
                item.bookingRefId
              }}</span>
            </template>
            <template #header.departure>
              <span class="status">Departure</span>
            </template>
            <template v-slot:[`item.departure`]="{ item }">
              <span class="td-style text-capitalize">{{ item.departure }}</span>
            </template>
            <template #header.arrival>
              <span class="status">Arrival</span>
            </template>
            <template v-slot:[`item.arrival`]="{ item }">
              <span class="td-style text-capitalize">{{ item.arrival }}</span>
            </template>
            <template #header.tripDate>
              <span class="status">Trip Date</span>
            </template>
            <template v-slot:[`item.tripDate`]="{ item }">
              <span class="td-style text-capitalize">{{ item.tripDate }}</span>
            </template>
            <template #header.tripTime>
              <span class="status">Trip Time</span>
            </template>
            <template v-slot:[`item.tripTime`]="{ item }">
              <span class="td-style text-capitalize">{{
                getTime(item.tripDate, item.tripTime)
              }}</span>
            </template>
            <template #header.amountPaid>
              <span class="status">Amount Paid (&#8358;)</span>
            </template>
            <template v-slot:[`item.amountPaid`]="{ item }">
              <span class="td-style text-capitalize">{{
                  formatNumber(item.tripFare)
                }}</span>
            </template>
            <template #header.discountedAmount>
              <span class="status">Discounted Amount (&#8358;)</span>
            </template>
            <template v-slot:[`item.discountedAmount`]="{ item }">
              <span class="td-style text-capitalize">{{
                  formatNumber(item.discountedAmount)
                }}</span>
            </template>
            <template #header.bookingType>
              <span class="status">Booking Type</span>
            </template>
            <template v-slot:[`item.bookingType`]="{ item }">
              <span class="td-style text-capitalize">{{ item.bookingType }}</span>
            </template>
            <template #header.bookedBy>
              <span class="status">Booked By</span>
            </template>
            <template v-slot:[`item.bookedBy`]="{ item }">
              <span class="td-style text-capitalize">{{ item.bookedBy }}</span>
            </template>
            <template #header.serviceProvider>
              <span class="status">Service Provider</span>
            </template>
            <template v-slot:[`item.serviceProvider`]="{ item }">
              <span class="td-style text-capitalize">{{ item.transportCompany }}</span>
            </template>
            <template #header.status>
              <span class="status">Status</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span class="td-style text-capitalize">{{ item.status }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon color="#004AAD"
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                      @click="handleViewABooking(item)"
                    >
                      View
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center"
                        @click="handlePrintBooking(item)"
                    >
                      <v-icon color="#004AAD" >mdi-printer-outline</v-icon>
                      Print
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center"
                        @click="handleShowDeleteBookingModal(item)"
                    >
                      <v-icon color="#004AAD" >mdi-delete-outline</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

          </v-data-table>
        </div>
      </div>
        <section slot="pdf-content" v-if="Object.keys(selectedBooking).length">
          <booking-receipt  :booking="selectedBooking" ref="customerReceipt" />
        </section>

    </v-col>
    <modal section="delete-booking" :dialog="deleteModalVisible"
           :description="'Are you sure you want to delete this booking with Ref Id: '+bookingToDelete.bookingRefId+
           ' This action cannot be reversed'" title="Delete Booking"
           @close="handleCloseDeleteBookingModal" @deleteBooking="handleDeleteBooking" :loading="deleteModalLoading" />
  </v-col>
</template>

<script>
import {
  deleteBookingByRefId,
  getAllBookingsByPageSize, getBookingCounts,
  getBookingStatByDateRange,
} from "../../services/api/APIService";
import dayjs from "dayjs";
import Loader from "../../components/reuseables/Loader";
import BookingReceipt from "./BookingReceipt.vue";
import Modal from "../../components/reuseables/Modal.vue";
import AnalyticCountCard from "../../components/reuseables/AnalyticCountCard.vue";

export default {
  name: "AllBooking",
  components: {AnalyticCountCard, Modal, BookingReceipt, Loader },
  data() {
    return {
      headers: [
        { text: "", value: "sno" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "", value: "bookingRefId" },
        { text: "", value: "departure" },
        { text: "", value: "arrival" },
        { text: "", value: "tripDate" },
        { text: "", value: "tripTime" },
        { text: "", value: "amountPaid" },
        { text: "", value: "discountedAmount" },
        { text: "", value: "bookingType" },
        { text: "", value: "bookedBy" },
        {text: "", value: "serviceProvider"},
        {text: "", value: "status"},
        { text: "", value: "actions", align: "end" },
      ],
      paginator:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 1,
        pageStop: 10,
        pageCount: 1,
        itemsLength: 0
      },
      pageData:{
        page :0,
        pageSize:200
      },
      itemsPerPage:[10,20,50,100,200,500,100,2000,5000,10000],
      pageDataLoading: false,
      totalBookingCount:null,
      not_found: "Bookings will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      bookings: [],
      filterBookings:[],
      loading: false,
      selectedBooking:{},
      deleteModalVisible: false,
      bookingToDelete:{},
      deleteModalLoading: false,
      todayBookings:{},
      monthBookings:{},


    };
  },
  computed:{
    Dayjs() {
      return dayjs
    },

    numberOfPages () {
      return Math.ceil(this.bookings.length / this.paginator.itemsPerPage)
    },
    getCurrentMonth(){
      return dayjs().format("MMM,YYYY");
    },
    getCurrentDay(){
      return dayjs().format("ll");
    },
  },
  watch:{
    search:{
      handler : function (val){
        if (val){
          this.filterMatchedBookings()
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods: {
    filterMatchedBookings() {
      this.filterBookings = this.bookings.filter((booking) => {
        return !this.search || (booking.bookingRefId.toLowerCase().startsWith(this.search.toLowerCase()));
      })
    },
    getFilterBookings(bookingType){
      if (bookingType === 'All'){
        this.filterBookings = this.bookings
      }
      else if (bookingType === 'Offline'){
        this.filterBookings = this.bookings.filter(booking => booking.bookingType === 'Offline')
      }
      else if (bookingType === 'Online'){
        this.filterBookings = this.bookings.filter(booking => booking.bookingType === 'Online')
      }
    },
    handleCloseDeleteBookingModal() {
      this.deleteModalVisible = !this.deleteModalVisible;
    },
    handleShowDeleteBookingModal(booking){
      this.bookingToDelete = booking
      this.deleteModalVisible = true
    },

  async allBookingCount(){
     await getBookingCounts().then(res =>{
        this.totalBookingCount = res.data.totalBooking
      }).catch((err) => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
  },

   async getTodayBookingStat(){
      let data = {}
      data.startDate = dayjs().format("YYYY-MM-DD")
      data.endDate = dayjs().format("YYYY-MM-DD")
      await getBookingStatByDateRange(data).then( res =>{
        this.todayBookings = res.data
      }).catch((err) => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
   async getCurrentMonthBookingStat(){
      let data = {}
     data.startDate = dayjs().startOf('month').format('YYYY-MM-DD')
     data.endDate = dayjs().endOf('month').format('YYYY-MM-DD')
     await getBookingStatByDateRange(data).then(res =>{
       this.monthBookings = res.data
     }).catch((err) => {
       this.$displaySnackbar({
         message: err.response.data.details[0],
         success: false,
       });
     });
   },
    handleViewABooking(booking) {
      sessionStorage.setItem("currentBooking", JSON.stringify(booking));
      this.$router.push({name:'ViewABooking'})
    },
    handleDeleteBooking(){
      this.deleteModalLoading = true
      let data = {}
      data.bookingRefId = this.bookingToDelete.bookingRefId;
       deleteBookingByRefId(data).then(async () => {
        await this.getBookings()
        this.deleteModalVisible = false
         this.deleteModalLoading = false
      }).finally(()=> this.deleteModalLoading = false)
    },
    handlePrintBooking(booking){
      this.selectedBooking = booking
      this.$refs.customerReceipt.print()
    },
    getFirstName(item) {
      if (item.travellerDetails.length) {
        return item.travellerDetails[0].firstName;
      }
      else {
        return ""
      }
    },
    getLastName(item) {
      if (item.travellerDetails.length) {
        return item.travellerDetails[0].lastName;
      }
      else {
        return ""
      }
    },
    async getBookings(showLoading) {
      if (showLoading) {
        this.loading = true;
      }
      else {
        this.pageDataLoading = true
      }
      await getAllBookingsByPageSize(this.pageData)
        .then((res) => {
          if (this.bookings.length){
            this.bookings = this.bookings.concat(res.data)
          }
          else {
            this.bookings = res.data
          }
          this.bookings.sort(function (a, b) {
            return dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate();
          });
          this.bookings.forEach((booking, index) => (booking.sno = index + 1));
          this.filterMatchedBookings()
          this.loading = false;
          this.pageDataLoading = false
        })
        .catch((err) => {
          this.loading = false;
          this.pageDataLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    handleNext(){
      if (this.paginator.page < this.bookings.length){
        this.paginator.page++
        console.log(this.numberOfPages, this.bookings.length,this.paginator.page)
        if (this.paginator.page === this.numberOfPages && this.bookings.length < this.totalBookingCount){
          this.pageData.page++
          this.getBookings(false).then(()=> this.pageDataLoading = false)
        }
      }
    },
    handlePrevious () {
      if (this.paginator.page - 1 >= 1)
        this.paginator.page -= 1
    },

    getTime(date, time) {
      return dayjs(date + time).format("LT");
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
      else {
        return 0
      }
    },
  },
  async created() {
    this.loading = true;
    await this.getTodayBookingStat()
    await this.getCurrentMonthBookingStat()
    await this.allBookingCount()
    await this.getBookings(true);


  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.showings {
  font-family: "Inter",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;

  span {
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0 4px 40px 0 #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 0;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
